import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";

import {isEmpty} from "utils.js";

const styles = () => ({
  input: {
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit"
  },
  inputLabel: {
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit"
  }
});

const TYPES = {
  string: "text",
  number: "text",
  integer: "text",
  tel: "tel",
  email: "email",
  regexp: "text"
};

const INTEGER_FORMAT = new RegExp(/^0$|^[-]?[1-9]{1}[0-9]*$/);
const NUMBER_FORMAT = new RegExp(/^0$|^-0$|^[-]?(0{1}|[1-9]{1}[0-9]*)\.{1}[0-9]+$|^([-]?[1-9]{1}[0-9]*)$/);

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false
    };

    this.rawPattern = this.props.pattern;
    this.pattern = this.props.pattern ? new RegExp(this.props.pattern) : null;
    this.input = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.rawPattern !== this.props.pattern) {
      this.rawPattern = this.props.pattern;
      this.pattern = this.props.pattern ? new RegExp(this.props.pattern) : null;
    }
    if (this.props.value !== prevProps.value) {
      this.setState({value: this.props.value, error: !this.validate(this.state.value)});
    } else if (
      prevState.value !== this.state.value ||
      this.props.type !== prevProps.type ||
      prevProps.min !== this.props.min ||
      prevProps.max !== this.props.max ||
      prevProps.pattern !== this.props.pattern ||
      prevProps.required !== this.props.required ||
      prevProps.disabled !== this.props.disabled
    ) {
      this.setState({error: !this.validate(this.state.value)});
    }
  }

  validate = (value) => {
    if (this.props.required && isEmpty(value)) {
      return false;
    } else if (isEmpty(value)) {
      return true;
    } else if (this.props.type === "integer" && INTEGER_FORMAT.test(value)) {
      return (
        (this.props.min === null || value >= this.props.min) && (this.props.max === null || value <= this.props.max)
      );
    } else if (this.props.type === "number" && NUMBER_FORMAT.test(value)) {
      return (
        (this.props.min === null || value >= this.props.min) && (this.props.max === null || value <= this.props.max)
      );
    } else if (this.props.type === "string") {
      return this.pattern ? this.pattern.test(value) : true;
    } else if (this.props.type === "tel") {
      return true;
    } else if (this.props.type === "email") {
      return true;
    } else if (this.props.type === "regexp") {
      try {
        new RegExp(value);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  onChange = (value) => {
    this.setState({value});
    if (this.props.type === "integer") {
      if (!value) {
        this.props.onChange(null);
      } else if (INTEGER_FORMAT.test(value)) {
        this.props.onChange(parseInt(value));
      }
    } else if (this.props.type === "number") {
      if (!value) {
        this.props.onChange(null);
      } else if (NUMBER_FORMAT.test(value)) {
        this.props.onChange(parseFloat(value));
      }
    } else {
      this.props.onChange(value);
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <MuiTextField
        inputRef={this.input}
        error={this.props.errorText ? true : this.state.error}
        InputProps={{className: classes.input}}
        fullWidth={true}
        size="small"
        color="primary"
        variant={this.props.variant}
        type={TYPES[this.props.type]}
        disabled={this.props.disabled}
        required={this.props.required}
        label={this.props.label}
        helperText={this.props.errorText || this.props.helperText}
        // value cannot be null or undefined or there will be red and yellow warnings in the console
        value={this.state.value === null || this.state.value === undefined ? "" : this.state.value}
        onChange={(event) => this.onChange(event.target.value)}
        onBlur={(event) => this.onChange(event.target.value.trim())}
        InputLabelProps={{
          className: classes.inputLabel
        }}
      />
    );
  }
}

TextField.propTypes = {
  pattern: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  variant: PropTypes.string,
  type: PropTypes.oneOf(["string", "integer", "number", "tel", "email", "regexp"]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object
};

TextField.defaultProps = {
  pattern: null,
  variant: "outlined",
  type: "string",
  min: null,
  max: null,
  disabled: false,
  required: false,
  label: "",
  errorText: "",
  helperText: ""
};

export default withStyles(styles)(TextField);
