import {decorate, observable, action} from "mobx";

import UsecaseAuth from "usecase/auth.js";
import error from "usecase/errors.js";

class Auth {
  changePassword = false;
  sessionLifetime = 0;
  authenticated = false;
  sessionExpired = false;

  constructor() {
    this.storage = new UsecaseAuth();
  }

  async update() {
    try {
      let data = await this.storage.getState();
      this.sessionLifetime = data.item.session_lifetime;
      this.authenticated = true;
      this.sessionExpired = false;
    } catch (e) {
      if (e instanceof error.UnauthorizedError) {
        this.changePassword = false;
        this.sessionLifetime = 0;
        this.sessionExpired = this.sessionExpired || this.authenticated;
        this.authenticated = false;
      } else {
        throw e;
      }
    }
  }

  async login(company, username, password) {
    await this.storage.login(company, username, password);
  }

  async logout(sessionExpired = false) {
    this.changePassword = false;
    this.sessionLifetime = 0;
    this.authenticated = false;
    this.sessionExpired = sessionExpired;
    await this.storage.logout();
  }

  async stayConnected() {
    await this.storage.stayConnected();
    await this.update();
  }
}

Auth = decorate(Auth, {
  changePassword: observable,
  sessionExpired: observable,
  authenticated: observable,
  session_lifetime: observable,
  update: action,
  login: action,
  logout: action
});

export default new Auth();
