import React from "react";
import Fade from "@material-ui/core/Fade";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_window: false,
      enable_on_click: true,
      enable_on_keydown: true,
      zIndexMax: false
    };

    this.Modal = React.createRef();

    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active_window !== this.state.active_window && this.state.active_window) {
      this.Modal.current.focus();
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  show() {
    this.setState({active_window: true});
    document.body.style.overflow = "hidden";
  }

  close() {
    this.setState({active_window: false});
    document.body.style.overflow = "auto";
  }

  closeModalByBackdrop = (event) => {
    if (event.target === this.Modal.current) {
      this.close();
    }
  };

  onKeyPressed = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27) {
      // Escape key
      this.close();
    }
  };

  render() {
    return (
      <Fade in={this.state.active_window}>
        <div
          ref={this.Modal}
          className={`modalBackdrop ${this.state.zIndexMax ? "modalBackdropOnTop" : ""}`}
          tabIndex="1"
          onKeyDown={this.state.enable_on_keydown ? this.onKeyPressed : null}
          onClick={this.state.enable_on_click ? this.closeModalByBackdrop : null}
        >
          {this.renderModal()}
        </div>
      </Fade>
    );
  }
}

export default Modal;
