import React from "react";

import {Form, FormRow, FormSection} from "app/components/Form.jsx";
import PasswordField from "app/elements/PasswordField";
import SelectBox from "app/elements/SelectBox.jsx";
import TextField from "app/elements/TextField.jsx";
import Profile from "usecase/profile.js";
import error from "usecase/errors.js";
import {toasts} from "store";

const TIME_FORMATS = [
  {id: "%H:%M:%S", title: "24-Hour Time"},
  {id: "%I:%M:%S%p", title: "AM/PM"}
];

const DATE_FORMATS = [
  {id: "%d.%m.%Y", title: "DD.MM.YYYY"},
  {id: "%d-%m-%Y", title: "DD-MM-YYYY"},
  {id: "%d/%m/%Y", title: "DD/MM/YYYY"},
  {id: "%m.%d.%Y", title: "MM.DD.YYYY"},
  {id: "%m-%d-%Y", title: "MM-DD-YYYY"},
  {id: "%m/%d/%Y", title: "MM/DD/YYYY"},
  {id: "%Y.%m.%d", title: "YYYY.MM.DD"},
  {id: "%Y-%m-%d", title: "YYYY-MM-DD"},
  {id: "%Y/%m/%d", title: "YYYY/MM/DD"}
];

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      confirm_new_password: "",
      errorMsg: null
    };

    this.profile = new Profile();
    this.form = React.createRef();
  }

  async componentDidMount() {
    try {
      let data = await this.profile.get();
      this.setState({item: data.item});
      delete this.state.item.master;
      delete this.state.item.agency_id;
    } catch (e) {
      if (e instanceof error.HttpError) {
        toasts.add(e.message, "Profile loading", "error");
      }
    }
  }

  sendData = async () => {
    try {
      await this.profile.update(this.state.item);
      toasts.add("Success", "Update", "success");
      this.setState((prevState) => ({
        item: {...prevState.item, password: "", current_password: ""},
        confirm_new_password: ""
      }));
    } catch (e) {
      if (e instanceof error.HttpError) {
        this.form.current.setFormAlertMessage(e.message);
      } else {
        throw e;
      }
    }
  };

  update = async () => {
    if (
      this.state.item.password !== undefined &&
      this.state.item.password !== "" &&
      this.state.item.password !== this.state.confirm_new_password
    ) {
      this.setState({errorMsg: "Passwords does not match. You must confirm the new password."});
    } else {
      if (this.state.item.current_password === "" || this.state.item.password === "") {
        delete this.state.item.current_password;
        delete this.state.item.password;
        await this.sendData();
      } else {
        await this.sendData();
      }
    }
  };

  updateField = (field, value) => {
    this.setState((prevState) => ({item: {...prevState.item, [field]: value}}));
  };

  render() {
    return (
      <Form ref={this.form} submitButtonText="Update profile" onSubmit={this.update}>
        <FormSection title="Password">
          <FormRow title="Current password">
            <PasswordField
              required={false}
              value={this.state.item.current_password}
              onChange={(value) => this.updateField("current_password", value)}
            />
          </FormRow>
          <FormRow title="New password">
            <PasswordField
              required={false}
              value={this.state.item.password}
              onChange={(value) => this.updateField("password", value)}
            />
          </FormRow>
          <FormRow title="Confirm new password">
            <PasswordField
              required={false}
              value={this.state.confirm_new_password}
              onChange={(value) => this.setState({confirm_new_password: value})}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormRow title="Name">
            <TextField required value={this.state.item.name} onChange={(value) => this.updateField("name", value)} />
          </FormRow>
          <FormRow title="Email">
            <TextField
              type="email"
              value={this.state.item.email}
              onChange={(value) => this.updateField("email", value)}
            />
          </FormRow>
          <FormRow title="Phone number">
            <TextField
              type="tel"
              value={this.state.item.phone}
              onChange={(value) => this.updateField("phone", value)}
            />
          </FormRow>
          <FormRow title="Department">
            <TextField value={this.state.item.department} onChange={(value) => this.updateField("department", value)} />
          </FormRow>
          <FormRow title="Country">
            <TextField value={this.state.item.country} onChange={(value) => this.updateField("country", value)} />
          </FormRow>
          <FormRow title="Region">
            <TextField value={this.state.item.region} onChange={(value) => this.updateField("region", value)} />
          </FormRow>
          <FormRow title="City">
            <TextField value={this.state.item.city} onChange={(value) => this.updateField("city", value)} />
          </FormRow>
          <FormRow title="Date format">
            <SelectBox
              value={this.state.item.date_format}
              setValue={(value) => this.updateField("date_format", value)}
              items={DATE_FORMATS}
            />
          </FormRow>
          <FormRow title="Time format">
            <SelectBox
              value={this.state.item.time_format}
              setValue={(value) => this.updateField("time_format", value)}
              items={TIME_FORMATS}
            />
          </FormRow>
        </FormSection>
      </Form>
    );
  }
}

export default Edit;
