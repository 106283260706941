import React from "react";
import PropTypes from "prop-types";

class Summary extends React.Component {
  render() {
    return <div className="summary">{this.props.children}</div>;
  }
}

Summary.propTypes = {
  children: PropTypes.node
};

class Section extends React.Component {
  render() {
    return (
      <div className="summarySection">
        {this.props.title ? <div className="summarySection-header">{this.props.title}</div> : null}
        <div className="summarySection-content" style={{justifyContent: this.props.justifyContent}}>
          {this.props.children}
        </div>
        {this.props.separator ? <div className="separator" /> : null}
      </div>
    );
  }
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  justifyContent: PropTypes.string,
  separator: PropTypes.bool
};

Section.defaultProps = {
  title: "",
  justifyContent: "flex-start",
  separator: true
};

class Row extends React.Component {
  render() {
    return (
      <div className="summarySectionRow">
        <div className="summarySectionRow-title">{this.props.title}</div>
        <div className="summarySectionRow-text">{this.props.text}</div>
      </div>
    );
  }
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Row.defaultProps = {
  title: "",
  text: ""
};

export {Summary, Section, Row};
