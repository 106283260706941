import React from "react";
import PropTypes from "prop-types";

import icons from "assets/icons";

class Icon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trash: icons.trash,
      index: {
        edit: icons.cog,
        sessions: icons.list
      },
      topBar: {
        cdp: icons.server,
        customerService: icons.headset,
        profile: icons.profile,
        services: icons.grid3x3Fill
      },
      sidebar: {
        edit: icons.cog,
        home: icons.profile,
        sessions: icons.list
      }
    };
  }

  getClass = () => {
    let parts = this.props.name.split(".");
    let result = this.state;
    let part;
    while ((part = parts.shift())) {
      result = result[part];
    }
    return result;
  };

  render() {
    let icon = this.getClass();
    if (!icon) {
      throw new Error(`Invalid icon: ${this.props.name}`);
    }
    return (
      <span title={this.props.title} style={{display: "inline-flex", color: "inherit"}}>
        <svg className="icon" viewBox={icon.viewBox} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          {icon.d.map((path, index) => {
            return <path key={`${this.props.name}.${index}`} fillRule="evenodd" d={path} />;
          })}
        </svg>
      </span>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Icon;
