import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./sass/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import theme from "theme.js";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {strftime} from "utils";

const defaultTheme = createMuiTheme(theme);

Date.prototype.toJSON = function () {
  return strftime(this, "%Y-%m-%dT%H:%M:%S%z");
};

ReactDOM.render(
  <ThemeProvider theme={defaultTheme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
