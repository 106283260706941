import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = () => ({
  input: {
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit",
    "& ::-ms-reveal": {
      display: "none"
    }
  }
});

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  render() {
    const {classes} = this.props;
    const InputComponent = this.props.variant === "outlined" ? OutlinedInput : Input;

    return (
      <FormControl
        variant={this.props.variant}
        size={this.props.variant === "outlined" ? "small" : "medium"}
        required={this.props.required}
        fullWidth
      >
        {this.props.label ? <InputLabel>Password</InputLabel> : null}
        <InputComponent
          className={classes.input}
          type={this.state.showPassword ? "text" : "password"}
          value={this.props.value}
          onChange={(event) => this.props.onChange(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => this.setState({showPassword: !this.state.showPassword})}
                edge="end"
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          required={this.props.required}
        />
        <FormHelperText id="outlined-weight-helper-text">
          {this.props.required ? `Required. ${this.props.helperText}` : this.props.helperText}
        </FormHelperText>
      </FormControl>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  classes: PropTypes.object
};

PasswordField.defaultProps = {
  label: false,
  variant: "outlined",
  value: "",
  required: true,
  helperText: ""
};

export default withStyles(styles)(PasswordField);
