export let isEmpty = function (value) {
  return (
    !(value instanceof Date) &&
    (value === null ||
      value === undefined ||
      value === "" ||
      (typeof value === "object" && (value.length === 0 || Object.keys(value).length === 0)))
  );
};

export let queryParams = function (params, except) {
  let result = [];
  for (let key in params) {
    if (except !== undefined && except.includes(key)) {
      continue;
    }
    if (params[key] !== "" && params[key] !== null) {
      result.push(key + "=" + params[key]);
    }
  }
  return result.join("&");
};

let addLeadZero = function (num) {
  return (num < 10 ? "0" : "") + num;
};

let ampmFormat = function (hours) {
  hours = hours % 12;
  hours = hours ? hours : 12;
  return addLeadZero(hours);
};

let addOffset = function (offset) {
  if (offset === 0) {
    return "+00:00";
  } else {
    offset *= -1;
    return (offset >= 0 ? "+" : "-") + addLeadZero(Math.floor(offset / 60)) + ":" + addLeadZero(offset % 60);
  }
};

export let strftime = function (date, format) {
  let result = format;

  let map = {
    "%d": (date) => addLeadZero(date.getDate()),
    "%m": (date) => addLeadZero(date.getMonth() + 1),
    "%Y": (date) => date.getFullYear(),
    "%H": (date) => addLeadZero(date.getHours()),
    "%I": (date) => ampmFormat(date.getHours()),
    "%M": (date) => addLeadZero(date.getMinutes()),
    "%S": (date) => addLeadZero(date.getSeconds()),
    "%p": (date) => (addLeadZero(date.getHours()) >= 12 ? " pm" : " am"),
    "%z": (date) => addOffset(date.getTimezoneOffset())
  };

  for (let key in map) {
    while (result.indexOf(key) > -1) {
      result = result.replace(key, map[key](date));
    }
  }

  return result;
};
