import Base from "usecase/base.js";

class Auth extends Base {
  constructor() {
    super("/auth/");
  }

  async getState() {
    return await this.fetch(this.url);
  }

  async login(company, username, password) {
    return await this.fetch(this.url, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({company: company, username: username, password: password})
    });
  }

  async logout() {
    return await this.fetch(this.url + "logout");
  }

  async stayConnected() {
    return await this.fetch(this.url + "stay_connected");
  }
}

export default Auth;
