import React from "react";
import {Link} from "react-router-dom";

import {profile} from "store";
import Icon from "app/elements/Icon.jsx";

class Index extends React.Component {
  render() {
    return (
      <div className="index">
        <div className="index-avatar">
          <div className="index-avatarText">{profile.name.length !== 0 ? profile.name[0].toUpperCase() : ""}</div>
        </div>
        <h1 className="index-title">Welcome, {profile.name}</h1>
        <div className="index-text">Manage your info, privacy and security to make Datawave work better for you.</div>
        <div className="d-flex">
          <Link to="/edit">
            <div className="index-item margin-r15">
              <Icon name="index.edit" title="Edit Info" />
              <div className="index-itemText">Edit your profile info</div>
            </div>
          </Link>
          <Link to="/sessions">
            <div className="index-item">
              <Icon name="index.sessions" title="Sessions" />
              <div className="index-itemText">Check you sessions</div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Index;
