import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Toolbar from "@material-ui/core/Toolbar";

import Icon from "app/elements/Icon.jsx";
import {auth, profile} from "store";

const styles = () => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    zIndex: 97,
    backgroundColor: "#fff",
    boxShadow: "none",
    transition: "all 200ms linear"
  },
  toolBar: {
    display: "flex",
    justifyContent: "flex-end"
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "380px",
    paddingTop: "15px",
    boxShadow: "0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)",
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "4px"
  },
  logoutButton: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  services: {
    display: "flex",
    padding: "20px 10px 10px 20px",
    boxShadow: "0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)",
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "4px",
    "& .icon": {
      height: "28px",
      width: "28px",
      marginBottom: "7px"
    }
  },
  serviceButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "78px",
    width: "78px",
    marginRight: "10px",
    marginBottom: "10px",
    borderRadius: "4px",
    color: "#646c9a",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(250, 250, 250)"
    }
  },
  serviceLink: {
    position: "absolute",
    top: "10px",
    left: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "13px",
    transition: "all 150ms linear"
  },
  serviceButtonText: {
    width: "65px",
    textAlign: "center"
  },
  servicesIcon: {
    marginRight: "15px",
    "& .icon": {
      height: "22px",
      width: "22px"
    }
  },
  profileIcon: {
    "& .icon": {
      height: "22px",
      width: "22px"
    }
  },
  fillContainer: {
    height: "100%",
    width: "100%"
  },
  profileName: {
    marginBottom: "15px",
    color: "#646c9a",
    fontWeight: 500
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  }
});

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElement: null,
      currentMenu: null
    };

    this.menus = {
      profile: this.profile,
      services: this.services
    };
  }

  toggleMenu = (value) => {
    this.setState({currentMenu: this.state.currentMenu && this.state.currentMenu === value ? null : value});
  };

  closeMenu = (e) => {
    if (this.state.anchorElement && this.state.anchorElement.contains(e.target)) {
      return;
    }
    this.setState({currentMenu: null});
  };

  setAnchor = (e) => {
    this.setState({anchorElement: e.currentTarget});
  };

  logoutButton = () => {
    auth.logout();
  };

  profile = () => {
    const {classes} = this.props;
    return (
      <div className={classes.menuList}>
        <div className={classes.menuItem}>
          <div className={classes.profileName}>{profile.name}</div>
          <div className="margin-b15">
            <Link to="/edit">
              <Button variant="outlined" color="primary" onClick={this.closeMenu}>
                Manage your profile
              </Button>
            </Link>
          </div>
        </div>
        <Divider />
        <div className={classes.menuItem}>
          <Button variant="contained" color="primary" className={classes.logoutButton} onClick={this.logoutButton}>
            Sign out
          </Button>
        </div>
      </div>
    );
  };

  services = () => {
    const {classes} = this.props;
    return (
      <div className={classes.services}>
        <div className={classes.serviceButton}>
          <div className={classes.fillContainer} onClick={() => window.location.assign(process.env.REACT_APP_CDP_URL)}>
            <div className={classes.serviceLink}>
              <Icon name="topBar.cdp" title="CDP" />
              <span className={classes.serviceButtonText}>CDP</span>
            </div>
          </div>
        </div>
        <div className={classes.serviceButton}>
          <div
            className={classes.fillContainer}
            onClick={() => window.location.assign(process.env.REACT_APP_CUSTOMER_SERVICE_URL)}
          >
            <div className={classes.serviceLink}>
              <Icon name="topBar.customerService" title="Customer Service" />
              <span className={classes.serviceButtonText}>Customer Service</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              className={classes.servicesIcon}
              onClick={(e) => {
                this.setAnchor(e);
                this.toggleMenu("services");
              }}
            >
              <Icon name="topBar.services" title="Services" />
            </IconButton>
            <IconButton
              className={classes.profileIcon}
              onClick={(e) => {
                this.setAnchor(e);
                this.toggleMenu("profile");
              }}
            >
              <Icon name="topBar.profile" title="Container" />
            </IconButton>
            <Popper open={Boolean(this.state.currentMenu)} anchorEl={this.state.anchorElement} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.closeMenu}>
                      {this.state.currentMenu ? this.menus[this.state.currentMenu]() : <></>}
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

TopBar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(observer(TopBar));
