import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {isEmpty} from "utils.js";

const styles = () => ({
  inputLabelDefault: {
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit"
  },
  inputLabel: {
    fontSize: "inherit",
    color: "inherit"
  },
  select: {
    fontSize: "0.8125rem",
    fontWeight: "inherit",
    color: "#646c9a"
  },
  listSubheader: {
    position: "relative"
  },
  menuItem: {
    fontSize: "0.8125rem",
    fontWeight: "inherit",
    color: "#646c9a"
  },
  level0: {
    paddingLeft: "16px"
  },
  level1: {
    paddingLeft: "26px"
  },
  level2: {
    paddingLeft: "36px"
  }
});

class SelectBox extends React.Component {
  render() {
    const {classes} = this.props;

    let getLevelClass = (level) => {
      switch (level) {
        case 0:
          return classes.level0;
        case 1:
          return classes.level1;
        case 2:
          return classes.level2;
        default:
          return classes.level0;
      }
    };

    let renderItems = (item, level) => {
      // return [] - Array of Components instead of React.Fragment which does not trigger Select onChange handler
      return [
        !isEmpty(item.fields) ? (
          <ListSubheader className={[classes.listSubheader, getLevelClass(level)].join(" ")}>
            {item.title}
          </ListSubheader>
        ) : (
          <MenuItem
            component={item.link ? Link : "li"}
            to={item.link ? item.link : null}
            className={[classes.menuItem, getLevelClass(level)].join(" ")}
            value={item.id}
          >
            {item.title}
          </MenuItem>
        ),
        item.fields ? item.fields.map((nestedItem) => renderItems(nestedItem, level + 1)) : null
      ];
    };

    return (
      <FormControl variant="outlined" size="small" fullWidth={true}>
        {!isEmpty(this.props.label) ? (
          <InputLabel
            id={`selectBox-label-${this.props.label.split(" ")[0]}${this.props.label.length}`}
            className={!isEmpty(this.props.value) ? classes.inputLabel : classes.inputLabelDefault}
          >
            {this.props.label}
          </InputLabel>
        ) : null}
        <Select
          disabled={this.props.disabled}
          labelId={
            !isEmpty(this.props.label)
              ? `selectBox-label-${this.props.label.split(" ")[0]}${this.props.label.length}`
              : null
          }
          className={classes.select}
          // value cannot be null or undefined or there will be red and yellow warnings in the console
          value={this.props.value === null || this.props.value === undefined ? "" : this.props.value}
          onChange={(event) =>
            this.props.items.length !== 0 && Object.prototype.hasOwnProperty.call(this.props.items[0], "link")
              ? null
              : this.props.setValue(event.target.value)
          }
          label={this.props.label}
          multiple={this.props.multiple}
        >
          {this.props.emptyItem ? (
            <MenuItem value={null} className={classes.menuItem}>
              <em>None</em>
            </MenuItem>
          ) : null}
          {this.props.items.map((item) => renderItems(item, 0))}
        </Select>
      </FormControl>
    );
  }
}

SelectBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.bool]),
  items: PropTypes.array.isRequired,
  setValue: PropTypes.func,
  emptyItem: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  classes: PropTypes.object
};

SelectBox.defaultProps = {
  value: "",
  emptyItem: false,
  label: undefined, // null or "" raising a style bug if default values
  disabled: false,
  multiple: false
};

export default withStyles(styles)(SelectBox);
