class Theme {
  palette = {
    primary: {
      main: "#5d78ff"
    },
    secondary: {
      main: "#0abb87",
      contrastText: "#fff"
    }
  };

  typography = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      "sans-serif"
    ].join(","),
    button: {
      fontWeight: 500,
      textTransform: "none"
    }
  };

  overrides = {
    MuiAlert: {
      message: {
        fontSize: "0.8125rem",
        fontWeight: 400
      }
    }
  };

  customStyles = {
    dangerButton: {
      contained: {
        backgroundColor: "#d32f2f",
        "&:hover": {
          backgroundColor: "#c62828"
        }
      },
      outlined: {
        borderColor: "#d32f2f",
        color: "#d32f2f",
        "&:hover": {
          borderColor: "#c62828"
        }
      }
    }
  };
}

export default new Theme();
