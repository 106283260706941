import React from "react";
import PropTypes from "prop-types";
import {withStyles, withTheme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiTextField from "@material-ui/core/TextField";
import queryString from "query-string";

import LoadIndicator from "app/elements/LoadIndicator.jsx";
import {FormAlert} from "app/components/Form.jsx";
import PasswordField from "app/elements/PasswordField";
import {auth} from "store";
import error from "usecase/errors.js";

let MSG = {
  1: {message: "Session has been expired", severity: "info"}
};

const styles = () => ({
  formItem: {
    marginTop: "16px",
    marginBottom: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    "&:last-child": {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: "30px"
    }
  },
  input: {
    width: "100%"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    maxWidth: "315px",
    padding: "80px 50px",
    boxShadow: "0 2px 42px rgba(0, 0, 0, 0.1)"
  },
  title: {
    marginBottom: "5rem",
    textAlign: "center",
    lineHeight: 1.2,
    fontSize: "1.7rem",
    fontWeight: 500,
    color: "#67666e"
  },
  loginButton: {
    height: "50px",
    paddingLeft: "2.75rem",
    paddingRight: "2.75rem",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    "& .loadIndicator": {
      margin: "0 auto"
    },
    "& .loadIndicator-dot": {
      backgroundColor: "#a9aaa9"
    }
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      username: "",
      password: "",
      isLoading: false
    };

    this.FormAlert = React.createRef();
  }

  componentDidMount() {
    if (auth.sessionExpired) {
      this.FormAlert.current.setMessage("Session has been expired", "info");
    } else {
      let originSearch = queryString.parse(this.props.location.search);
      if (originSearch.code && MSG[originSearch.code]) {
        this.FormAlert.current.setMessage(MSG[originSearch.code].message, MSG[originSearch.code].severity);
      }
    }
  }

  componentWillUnmount() {
    this.setState({company: "", username: "", password: ""});
  }

  updateForm = (name, value) => {
    this.setState({[name]: value});
  };

  submitForm = async (event) => {
    event.preventDefault();
    try {
      this.setState({isLoading: true});
      await auth.login(this.state.company, this.state.username, this.state.password);
      await auth.update();
    } catch (e) {
      if (e instanceof error.HttpError) {
        this.FormAlert.current.setMessage(e.message);
      } else {
        throw e;
      }
    } finally {
      this.setState({isLoading: false});
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div className="wrapper">
        <form className={classes.form} id="loginFrom" onSubmit={this.submitForm}>
          <div className={classes.title}>
            <h3>Login</h3>
          </div>
          <FormAlert ref={this.FormAlert} />
          <div className={classes.formItem}>
            <MuiTextField
              className={classes.input}
              label="Company"
              value={this.state.company}
              onChange={(event) => this.updateForm("company", event.target.value)}
              onBlur={(event) => this.updateForm("company", event.target.value.trim())}
            />
          </div>
          <div className={classes.formItem}>
            <MuiTextField
              className={classes.input}
              label="Username"
              value={this.state.username}
              onChange={(event) => this.updateForm("username", event.target.value)}
              onBlur={(event) => this.updateForm("username", event.target.value.trim())}
            />
          </div>
          <div className={classes.formItem}>
            <PasswordField
              label
              variant="standard"
              required={false}
              value={this.state.password}
              onChange={(value) => this.updateForm("password", value)}
            />
          </div>
          <div className={classes.formItem}>
            <Button
              className={classes.loginButton}
              variant="contained"
              color="primary"
              type="submit"
              form="loginFrom"
              size="large"
              disabled={
                this.state.isLoading ||
                this.state.company === "" ||
                this.state.username === "" ||
                this.state.password === ""
              }
            >
              {this.state.isLoading ? <LoadIndicator /> : "Sign in"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

export default withTheme(withStyles(styles)(Login));
