import React from "react";

import {auth} from "store";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    auth.logout();
  }

  render() {
    return null;
  }
}

export default Logout;
