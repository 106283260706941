import React from "react";

import LoadIndicator from "app/elements/LoadIndicator.jsx";

class Loading extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <div className="loading">
          <div className="loadingText">Loading</div>
          <LoadIndicator />
        </div>
      </div>
    );
  }
}

export default Loading;
