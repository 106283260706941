import {queryParams} from "../utils";

import error from "./errors.js";

class Base {
  constructor(url) {
    this.url = url;
  }

  async process_response(response) {
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 204) {
      return null;
    } else {
      if (response.status >= 500) {
        throw new error.ServerError(response.status, "Internal Server Error");
      } else {
        let result = await response.json();
        if (response.status === 400) {
          throw new error.BadRequestError(result.error.message);
        } else if (response.status === 401) {
          throw new error.UnauthorizedError(result.error.message);
        } else if (response.status === 403) {
          throw new error.ForbiddenError(result.error.message);
        } else if (response.status === 404) {
          throw new error.NotFoundError(result.error.message);
        } else if (response.status === 422) {
          throw new error.ValidationError(result.error.message, result.error.path);
        } else {
          throw new error.HttpError(response.status, result.error.message);
        }
      }
    }
  }

  async fetch(url, params) {
    if (params !== undefined) {
      params["credentials"] = "include";
    } else {
      params = {credentials: "include"};
    }
    return await this.process_response(await fetch(process.env.REACT_APP_API_URL + url, params));
  }

  async search(searchParams) {
    let data = await this.fetch(this.url + "?" + queryParams(searchParams));
    data.meta["pages"] = Math.ceil(data.meta.found / data.meta.limit) || 1;
    return data;
  }

  async get(itemId) {
    return await this.fetch(this.url + "/" + itemId);
  }

  async create(data) {
    return await this.fetch(this.url, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async update(itemId, data) {
    return await this.fetch(this.url + "/" + itemId, {
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async delete(itemId) {
    return await this.fetch(this.url + "/" + itemId, {method: "DELETE"});
  }
}

export default Base;
