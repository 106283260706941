import Base from "usecase/base.js";

class Profile extends Base {
  constructor() {
    super("/profile");
  }

  async get() {
    let data = await this.fetch(this.url);
    delete data.item.id;
    delete data.item.username;
    delete data.item.company_id;
    delete data.item.created;
    delete data.item.modified;
    delete data.item.brands;
    delete data.item.permissions;
    delete data.item.enabled;
    // delete data.item.master;
    return data;
  }

  async sessions() {
    return [
      {
        at: "2020-04-24T11:12:37.102000",
        ip: "127.0.0.1",
        platform: "Desktop",
        device: "Apple Mac",
        os: "Mac OS X 10.15.4",
        browser: "Google Chrome 80.0.3987.163",
        last_activity: "2020-04-24T16:14:02.102000"
      },
      {
        at: "2020-04-23T15:34:55.102000",
        ip: "127.0.0.1",
        platform: "Desktop",
        device: "Apple Mac",
        os: "Mac OS X 10.15.4",
        browser: "Safari 13.1",
        last_activity: "2020-04-23T17:34:55.102000"
      }
    ];
  }

  async update(data) {
    if (!data.email) {
      data.email = null;
    }
    if (!data.phone) {
      data.phone = null;
    }
    return await this.fetch(this.url, {
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }
}

export default Profile;
