import React from "react";
import {Link} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Modal from "app/components/Modals/Modal.jsx";
import {auth} from "store";

const styles = () => ({
  marginRight15: {
    marginRight: "15px"
  }
});

const TIME_TO_SESSION_EXPIRE = 3600;

class ExpiredSession extends Modal {
  constructor(props) {
    super(props);
    this.state["enable_on_click"] = false;
    this.state["enable_on_keydown"] = false;
    this.state["zIndexMax"] = true;
    this.state["progress"] = 0;
    this.state["dots"] = "";

    this.progressTimer = null;
    this.checkSessionLifeTimeTimer = null;
  }

  componentDidMount() {
    this.checkSessionLifeTime();
    this.checkSessionLifeTimeTimer = setInterval(() => {
      this.checkSessionLifeTime();
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.checkSessionLifeTimeTimer);
  }

  checkSessionLifeTime = () => {
    if (auth.sessionLifetime < TIME_TO_SESSION_EXPIRE + 10) {
      if (!this.state.active_window) {
        this.setState({progress: Math.min(auth.sessionLifetime - 10, TIME_TO_SESSION_EXPIRE), dots: ""});
        this.show();
      }
    }
  };

  show = () => {
    if (!this.state.active_window) {
      super.show();
      this.progressTimer = setInterval(() => {
        if (this.state.progress === 1) {
          clearInterval(this.progressTimer);
          auth.logout(true);
        }
        this.setState((prevState) => ({
          progress: --prevState.progress
        }));
        this.forceUpdate();
      }, 1000);
    }
  };

  stayConnected = async () => {
    clearInterval(this.progressTimer);
    let timer = setInterval(() => {
      this.setState((prevState) => ({
        dots: prevState.dots.length > 2 ? "" : prevState.dots + "."
      }));
    }, 1000);

    await auth.stayConnected();
    clearInterval(timer);
    this.close();
  };

  renderModal() {
    const {classes} = this.props;

    let barWidth = () => {
      return this.state.progress * (100 / TIME_TO_SESSION_EXPIRE) + "%";
    };

    return (
      <div className="modal expiredSessionModal">
        <div className="modalTitle margin-b30">Session Timeout Notification</div>
        <div className="width350 margin-b30">
          <p className="margin-b15">Your session is about to expire.</p>
          <p className="margin-b15">Redirecting in {this.state.progress} seconds.</p>
          <div className="expiredSessionModal-bar">
            <div className="expiredSessionModal-barProgress" style={{width: barWidth()}}>
              <p className="expiredSessionModal-barText">{this.state.progress + "s"}</p>
            </div>
          </div>
        </div>
        <div>
          <Button component={Link} to="/logout" className={classes.marginRight15} variant="contained" color="default">
            Logout
          </Button>
          <Button variant="contained" color="primary" onClick={this.stayConnected}>
            Stay Connected{this.state.dots}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ExpiredSession);
