import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.formAlert = React.createRef();
  }

  submit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  render() {
    return (
      <form id="form" className="form" onSubmit={this.submit}>
        {this.props.children}
        <FormSection separator={false}>
          <FormRow>
            <FormAlert ref={this.formAlert} />
          </FormRow>
        </FormSection>
        {this.props.submitButton ? (
          <FormSection separator={false}>
            <FormRow>
              <Button
                variant={this.props.submitButtonVariant}
                color={this.props.submitButtonColor}
                type="submit"
                form="form"
              >
                {this.props.submitButtonText}
              </Button>
            </FormRow>
          </FormSection>
        ) : null}
      </form>
    );
  }
}

Form.propTypes = {
  submitButton: PropTypes.bool,
  submitButtonVariant: PropTypes.string,
  submitButtonColor: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

Form.defaultProps = {
  submitButton: true,
  submitButtonVariant: "contained",
  submitButtonColor: "primary",
  submitButtonText: "Submit"
};

class FormSection extends React.Component {
  render() {
    return (
      <div className="formSection">
        {this.props.title ? <div className="formSection-title">{this.props.title}</div> : null}
        {this.props.children}
        {this.props.separator ? <div className="separator" /> : null}
      </div>
    );
  }
}

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  separator: PropTypes.bool
};

FormSection.defaultProps = {
  title: "",
  separator: true
};

class FormRow extends React.Component {
  render() {
    return (
      <div className="formRow">
        <span className="formRow-label">
          {this.props.title} {this.props.required ? <sup>*</sup> : null}
        </span>
        <span className="formRow-content">{this.props.children}</span>
      </div>
    );
  }
}

FormRow.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node
};

FormRow.defaultProps = {
  title: "",
  required: false
};

class FormAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      severity: "info",
      message: ""
    };
  }

  setMessage = (message, severity) => {
    this.setState({message: message, severity: severity ? severity : "error"});
  };

  render() {
    return (
      <Collapse in={Boolean(this.state.message)}>
        <Alert
          variant="filled"
          severity={this.state.severity}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={() => this.setState({message: ""})}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {this.state.message}
        </Alert>
      </Collapse>
    );
  }
}

export {Form, FormSection, FormRow, FormAlert};
