import React from "react";
import {observer} from "mobx-react";

import Loading from "app/pages/Loading.jsx";
import {auth, profile} from "store";
import Container from "app/layout/Container.jsx";
import Login from "./app/pages/Login.jsx";
import httpErrors from "usecase/errors.js";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import queryString from "query-string";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      serverError: false
    };
  }

  async componentDidMount() {
    try {
      await auth.update();
      if (auth.authenticated) {
        await profile.update();
      }
      this.setState({loading: false});

      setInterval(async () => {
        if (auth.authenticated) {
          try {
            await auth.update();
            if (this.state.serverError) {
              this.setState({serverError: false});
            }
          } catch (e) {
            if (e instanceof httpErrors.ServerError) {
              this.setState({serverError: true});
            } else {
              throw e;
            }
          }
        }
      }, 3000);
    } catch (e) {
      if (e instanceof httpErrors.ServerError) {
        this.setState({serverError: true});
      } else {
        throw e;
      }
    }
  }

  render() {
    if (this.state.serverError && !auth.authenticated) {
      return "Internal Server Error. Sorry, something went wrong.";
    } else if (this.state.loading) {
      return <Loading />;
    } else if (!auth.authenticated) {
      return (
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      );
    } else {
      if (window.location.search) {
        let originSearch = queryString.parse(window.location.search);
        if (originSearch.continue) {
          window.location.assign(originSearch.continue);
        }
        return null;
      } else {
        return (
          <Router>
            <Container />
          </Router>
        );
      }
    }
  }
}

export default observer(App);
