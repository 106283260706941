import React from "react";
import {observer} from "mobx-react";

import AuthorizedRoutes from "AuthorizedRoutes.jsx";
import ExpiredSession from "app/components/Modals/ExpiredSession.jsx";
import Sidebar from "app/layout/Sidebar.jsx";
import TopBar from "app/layout/TopBar.jsx";

class Container extends React.Component {
  render() {
    return (
      <div className="container">
        <Sidebar />
        <div className="containerContentWrap">
          <TopBar />
          <div className="containerContent">
            <ExpiredSession />
            <AuthorizedRoutes />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Container);
