import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import {Summary, Section, Row} from "app/components/Summary.jsx";
import Profile from "usecase/profile.js";
import error from "usecase/errors.js";
import {strftime} from "utils.js";
import {profile, toasts} from "store";
import theme from "theme.js";

const styles = () => ({
  terminateButton: theme.customStyles.dangerButton.contained
});

const FIELDS = [
  {id: "last_activity", title: "Last activity"},
  {id: "at", title: "Started"},
  {id: "ip", title: "IP"},
  {id: "platform", title: "Platform"},
  {id: "device", title: "Device"},
  {id: "os", title: "OS"},
  {id: "browser", title: "Browser"}
];

class Sessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };

    this.profile = new Profile();
  }

  async componentDidMount() {
    try {
      let data = await this.profile.sessions();
      this.setState({items: data});
    } catch (e) {
      if (e instanceof error.HttpError) {
        toasts.add(e.message, "Profile", "error");
      }
    }
  }

  render() {
    const {classes} = this.props;

    let renderSession = () => {
      return this.state.items.map((item, index) => {
        return (
          <Section key={`profile-session-${index}`} justifyContent="center">
            {FIELDS.map((field, index) => {
              return (
                <Row
                  key={`profile-session-field-${index}`}
                  title={field.title}
                  text={
                    field.id === "last_activity" || field.id === "at"
                      ? strftime(new Date(item[field.id]), profile.datetimeFormat)
                      : item[field.id]
                  }
                />
              );
            })}
            <div>
              <Button className={classes.terminateButton} variant="contained" color="primary">
                Terminate
              </Button>
            </div>
          </Section>
        );
      });
    };

    return <Summary>{renderSession()}</Summary>;
  }
}

Sessions.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Sessions);
