import React from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "app/elements/Icon.jsx";
import Logo from "assets/logo-min.png";

const styles = () => ({
  sidebar: {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 98,
    overflowY: "auto",
    height: "100vh",
    width: "150px",
    backgroundColor: "#fff",
    transition: "all 200ms linear",
    "@media (min-width: 1280px)": {
      width: "200px"
    }
  },
  list: {
    width: "100%",
    paddingTop: "40px",
    color: "#fff"
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0 0 25px",
    fontWeight: 500,
    fontSize: "1.9rem",
    color: "#5f6368"
  },
  logoImage: {
    display: "block",
    height: "40px",
    marginRight: "15px"
  },
  listItem: {
    padding: "5px 16px 5px 24px",
    borderRadius: "0 50px 50px 0",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#646c9a",
    transition: "all 200ms linear",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.039)"
    },
    "@media (min-width: 1280px)": {
      paddingLeft: "45px"
    }
  },
  listItemActive: {
    color: "#1967d2",
    backgroundColor: "#e8f0fe"
  },
  listItemText: {
    marginLeft: "10px"
  },
  listItemHomeText: {
    marginLeft: "11px"
  },
  homeIcon: {
    display: "flex",
    "& .icon": {
      height: "19px",
      width: "19px",
      marginLeft: "3.5px"
    }
  }
});

class Sidebar extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <div className={classes.sidebar}>
        <div className={classes.header}>
          <img src={Logo} alt="Datawave" className={classes.logoImage} />
          <span>Profile</span>
        </div>
        <div className={classes.list}>
          <List component="nav" aria-label="navigation menu">
            <ListItem
              exact
              component={NavLink}
              to="/"
              activeClassName={classes.listItemActive}
              button
              className={classes.listItem}
            >
              <span className={classes.homeIcon}>
                <Icon name="sidebar.home" title="Home" />
              </span>
              <ListItemText className={[classes.listItemText, classes.listItemHomeText].join(" ")} primary="Home" />
            </ListItem>
            <ListItem
              exact
              component={NavLink}
              to="/edit"
              activeClassName={classes.listItemActive}
              button
              className={classes.listItem}
            >
              <Icon name="sidebar.edit" title="Edit Info" />
              <ListItemText className={classes.listItemText} primary="Edit Info" />
            </ListItem>
            <ListItem
              exact
              component={NavLink}
              to="/sessions"
              activeClassName={classes.listItemActive}
              button
              className={classes.listItem}
            >
              <Icon name="sidebar.sessions" title="Sessions" />
              <ListItemText className={classes.listItemText} primary="Sessions" />
            </ListItem>
          </List>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(observer(Sidebar));
