import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Logout from "app/pages/Logout.jsx";
import Index from "app/pages/Index.jsx";
import Edit from "app/pages/Edit.jsx";
import Sessions from "app/pages/Sessions.jsx";
import NotFound from "app/pages/NotFound.jsx";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/login">
        <Redirect to="/" />
      </Route>
      <Route exact path="/" component={Index} />
      <Route exact path="/edit" component={Edit} />
      <Route exact path="/sessions" component={Sessions} />
      <Route exact path="/logout" component={Logout} />
      <Route component={NotFound} />
    </Switch>
  );
}
