import {decorate, observable, action} from "mobx";

class Toasts {
  items = [];

  add(msg, title, type) {
    this.items.push({msg, title, type});
    setTimeout(() => this.remove(this.items.length - 1), 5000);
  }

  remove(index) {
    this.items.splice(index, 1);
  }
}

Toasts = decorate(Toasts, {
  items: observable,
  add: action,
  remove: action
});

export default new Toasts();
